.wishlist{
    a{
        color: $black;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: $white;
        border: lighten($black,60%) solid 1px;
        position: absolute;
        z-index: 21;
        top: 10px;
        right: 10px;
        cursor: pointer;

        &:before{
            content: "";
            font-family: 'FontAwesome';
        }
        &:hover,
        &:focus{
            text-decoration: none;
        }

        span {
            display: none;
        }
    }

    .popover{
        min-width: 200px;
        .popover-title{
            font-family: $font-bold;
        }

        table{
            border: none;

            td{
                border: none;
                padding-left: 0;
                padding-right: 0;
            }

            tr{
                cursor: pointer;
                &:not(:last-child){
                    border-bottom: $grey solid 1px;
                }
            }
        }
    }
}