//Fonts
$font: 'myriad-pro';
$font-semibold: 'myriad-pro';
$font-bold: 'myriad-pro-bold';
$bdm: 'bdmregular';


//Color
$white: #ffffff;
$black:#000000;
$grey: #ece9eb;
$golden: #dd2228;
$red: #ff0000;
$blue: #0000ff;
$wine: #640036;