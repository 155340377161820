@import "common/theme_variables";
@import "animations";
@import "mixins/transitions";
@import "mixins/shadows";
@import "pagination";

.ajax_block_product{
    text-align: center;
    margin-bottom: 30px;
    padding:0 5px;
    position: relative;
    z-index: 2;

    @media(min-width:992px){
        &:nth-child(2n+1){
            clear: both;
        }
    }
    @media(max-width:991px){
        &:nth-child(2n+1){
            clear: both;
        }
    }

    @media(max-width:420px){
        width: 100% !important;
        margin-bottom: 0;
    }

    .product-container{
        padding: 20px;
        background: $white;
        position: relative;

        @media(max-width:420px){
            border-bottom:lighten($black,60%) solid 1px;
        }

        .left-block,
        .right-block{
            position: relative;
            z-index: 20;
        }     

        &:hover .product-name{
            color: lighten($black,40%);
            text-decoration: none;
        }

        .wishlist{
            opacity: 0;
        }
        &:hover .wishlist{
            opacity: 1;
        }

        .lnkdel{
            position: relative;
            z-index: 5;
        }

        .product-name{
            color: $black;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.2;
            display: block;
            margin-bottom: 10px;
            @include transition(all,ease,.3s);
            &:hover{
                color: lighten($black,40%);
                text-decoration: none;
            }

            strong{
                font-weight: normal;
            }
        }

        .features-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;

            img {
                height: 20px;
            }
        }

        .product-image-container{
            text-align: center;
            position: relative;
            margin-bottom: 15px;

            picture,
            img{
                display: inline-block;
                transition: all ease .3s;
            }

            .img_second {
                //display: none !important;
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                transform: translate(-50%, -50%) scale(0.6);
                @include transition(all,ease,.5s);
            }
        }

        &:hover {
            .img_first {
                opacity: 0;
            }
            .img_second {
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
            }
        }

        .shipping-free{
            font-size: 15px;
            line-height: 1.1;
            color: $golden;
            margin-bottom: 5px;
        }
    }
}

.availability{
    display: block;

    .label-instock,
    .label-outstock{
        font-size: 16px;
        line-height: 1.1;
    }

    .label-instock{
        color: $wine;
    }
    .label-outstock{
        color: lighten($black,40%);
    }
}

.new-box{
    background: $black;
    color: $white;
    display: inline-block;
    position: relative;

    &::before,
    &::after{
        width: 0;
        height: 0;
        border-style: solid;
        content: "";
        display: block;
        position: absolute;
        right: -12px;
    }

    &::before{
        border-width: 12px 12px 0 0;
        border-color: $black transparent transparent transparent;
        top: 0;
    }
    &::after{
        border-width: 12px 0 0 12px;
        border-color: transparent transparent transparent $black;
        bottom: 0;
    }
}
.empty-box,
.new-box{
    padding: 3px 8px;
    line-height: 1;
    margin: 5px 0;
    display: none
}
.sale-box {
    @extend .new-box;
    background: $wine;

    &::before{
        border-color: $black transparent transparent transparent;
    }
    &::after{
        border-color: transparent transparent transparent $black;
    }
}
.color_to_pick_list {
    list-style-type: none;
    padding: 0;
    margin: 15px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3px;

    &::before {
        display: none;
    }

    a {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: $grey solid 3px;
        transition: all ease .3s;
        overflow: hidden;

        img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            object-position: center center;
        }
    }
    li {
        width: 30px;
    }
    .selected a {
        border-color: $golden;
    }
    span {
        display: block;
        font-size: 11px;
        line-height: 1.1;
        margin-top: 5px;
        text-align: center;
    }
}