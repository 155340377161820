footer#footer{
    clear: both;
    background-color: $grey;
    color: $black;
    padding-top: 30px;
    padding-bottom: 30px;

    a{
        color: $black;
    }

    #payement-footer{
        .text-center{
            padding-top: 10px;
            font-size: 18px;
        }
    }


    #social_block{
        ul{
            padding: 0;
            margin: 0;
            list-style-type: none;
            @media(max-width:767px){
                text-align: center;
            }

            li{
                display: inline-block;
                float: left;
                margin-right: 10px;
                vertical-align: middle;
                line-height: 40px;

                @media(max-width:991px){
                    margin-bottom: 15px;
                }
                @media(max-width:767px){
                    float: none;
                    text-align: center;
                }

                a{
                    display: inline-block;
                    background: $black;
                    text-align: center;
                    color: $white;
                    width: 40px;
                    height: 40px;
                    font-size: 22px;
                    border-radius: 100%;
                    vertical-align: middle;
                    @include transition(all,ease,.3s);

                    &:hover{
                        background: $golden;
                    }

                    i{
                        display: inline-block;
                        vertical-align: middle;
                    }

                    span{
                        display: none;
                    }
                }
            }
        }
    }

    #block_various_links_footer{
        padding-top: 30px;

        ul{
            padding: 0;
            margin: 0;
            margin-bottom: 10px;
            list-style-type: none;
            font-size: 14px;
            font-family: $font-semibold;
            text-transform: uppercase;
            vertical-align: middle;

            li{
                display: inline-block;
                vertical-align: middle;

                &:not(:last-child):after{
                    content: "";
                    font-family: 'FontAwesome';
                    font-size: 6px;
                    vertical-align: middle;
                    display: inline-block;
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }
    .copyright{
        text-transform: uppercase;
        color: lighten($black,30%);
    }
    #steavisgarantisFooterText{
        text-align: center !important;
    }
}