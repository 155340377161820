.pagination{
    font-size: 24px;

    &#pagination_bottom{
        padding-top: 0;
    }

    a{
        color: $black;
        @include transition(all,ease,.3s);
        &:hover{
            color: lighten($black,40%);
        }
    }

    .active{
        color: $golden;
        font-weight: 600;
    }
}