#layer_cart{
    padding: 0 !important;
    border: none !important;
    position: absolute !important;

    .col-md-6{
        padding: 30px;
        font-size: 14px;

        .success{
            color: $golden;
            font-size: 24px;
            margin-bottom: 15px;
            display: block;
        }

        .product-name{
            font-size: 16px;
            text-transform: uppercase;
        }

        .layer_cart_img{
            width: 100px;
            height: 100px;
            float: left;
            margin-right: 15px;
        }

        .price{
            font-size: 18px;
        }

        .total{
            border-top: $grey solid 1px;
            padding-top: 20px;
            margin-top: 20px;
        }

        &.layer_cart_cart{
            background: $grey;
            font-size: 16px;
            
            .ajax_cart_product_txt_s{
                font-size: 24px;
                margin-bottom: 15px;
                display: block;
            }
            .total-cart{
                font-size: 24px;
                margin-bottom: 30px;
            }

            .button-container{
                padding-top: 30px;
                .btn{
                    margin-bottom: 15px;

                    @media(max-width:485px){
                        width: 100%;
                    }
                    
                    &.btn-primary{
                        float: left;
                    }
                    &.btn-default{
                        float: right;
                    }
                }
            }
        }
    }
}