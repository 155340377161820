#homepage-blog .col-sm-3 {
    a {
        color: $golden;
    }
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: top center;
        margin-bottom: 15px;
    }
}

body.module-smartblog-category, body.module-smartblog-details {
    main {
        .title_block_exclusive {
            font-size: 24px;
            margin-bottom: 15px;

            a {
                color: $black;
                display: block;
                min-height: 40px;
            }

            span{
                color: $golden;
            }
        }
        .articleContent a, .sdsarticle-des a {
            color: $golden;
        }

        p {
            font-size: 16px;
            line-height: 24px !important;
        }

        #smartblogcat {
            //@extend .row;

            .sdsarticleCat {
                //@extend .col-md-4;

                img {
                    width: 100%;
                    height: 400px;
                    object-fit: cover;
                    object-position: center center;
                }
                .read-more {
                    display: block;

                    strong {
                        font-weight: normal;
                    }
                }
            }
        }

        //sidebar
        .filters-container .block {
            padding:20px;
            background:$grey;
            border-bottom: $white solid 1px;

            a {
                color: $black;
            }

            .title_block {
                display: block;
                font-size: 18px;
                margin-bottom: 15px;
                position: relative;
                letter-spacing: 1.3px;

                span, a {
                    display: inline-block;
                    background:$grey;
                    position: relative;
                    z-index: 1;
                    padding-right: 15px;
                    color: $black;
                }

                &:after{
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 1px;
                    background: lighten($black,60%);
                    position: absolute;
                    top: 50%;
                    left: 0;
                    z-index: 0;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                }
            }
        }
        #recent_article_smart_blog_block_left li {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
            img {
                margin-right: 10px;
                width: 75px;
                height: 75px;
                float: left;
            }
        }
    }
    .addthis_button_tweet {
        margin-right: 9px !important;
    }
    .addthis_button_pinterest_pinit {
        margin-right: 3px !important;
    }
}

.account_creation label {
    width: initial !important;
}