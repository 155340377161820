
body#index{
    #homepage-slider{
        background: $grey;

        @media(max-width:767px){
            .container{
                padding: 0;
            }
            .square-back:before,
            .square-back:after{
                display: none;
            }
        }

        #homeslider{
            .owl-stage-outer{
                width: 100%;
            }

            a{
                display: block;
                position: relative;
                width: 100%;
                height: 640px;
                @media(max-width:600px){
                    height:300px;
                }
                @media(min-width:601px) and (max-width:767px){
                    height:350px;
                }
                @media(min-width:768px) and (max-width:992px){
                    height:400px;
                }
                background-position: top left;
                background-repeat: no-repeat;
                background-size: cover;

                .homeslider-description{
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    z-index: 2;
                    color: $white;
                    text-align: right;
                    font-size: 36px;
                    line-height: 1.1;
                    text-shadow: 0px 0px 3px rgba($black, 1);

                    @media(max-width:480px){
                        top: 50%;
                        left: 0;
                        right: auto;
                        width: 100%;
                        transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        text-align: center;
                        padding-left: 20px;
                        padding-right: 20px;
                        font-size: 28px;
                    }

                    p{
                        margin-bottom: 0;

                        span{
                            display: block;
                            font-size: 30px;
                            color: lighten($black,70%);

                            @media(max-width:480px){
                                font-size: 24px;
                            }
                        }
                    }

                    
                }

            }    
        }

        #homeslider-thumbnails{
            position: absolute;
            bottom: -30px;
            right: 30px;
            @media(max-width:320px){
                right: 15px;
            }
            z-index: 20;
            padding: 0;
            margin: 0;
            list-style-type: none;

            li{
                float: left;
                margin-left: 10px;

                a{
                    display: block;
                    width: 58px;
                    height: 58px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 100%;
                    border: lighten($black,65%) solid 4px;
                    @include transition(all,ease,.3s);

                    &:hover,
                    &.active{
                        border-color: $golden;
                    }
                }
            }
        }
    }

    @media(min-width:992px){
        h1{
            width: 50%;
        }
    }   
}