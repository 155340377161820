header#header{
    border-bottom: lighten($golden,30%) solid 4px;

    div.headline-container{
        background: $black;
        padding-top: 10px;
        padding-bottom: 10px;
        text-transform: uppercase;
        font-size: 13px;
        line-height:1;
        vertical-align: middle;
        letter-spacing: 1.3px;
        color: $white;
        
        *{
            font-weight: normal;
        }
    
        a{
            color: $white;
            @include transition(all,linear,0.4s);
            &:hover{
                text-decoration: none;
                color: lighten($black,60%);
            }
        }
        i{
            display: inline-block;
            margin-right: 5px;
        }

        #contact-header{
            float: left;
            > div{
                display: inline-block;
                margin-right: 10px;
            }
        }

        #reinsurance-top{
            float: right;
            width: 50%;
            text-align: right;
            padding: 2px 0 0 0;
            margin: 0;

            .icon-fr {
                span {
                    display: inline-block;
                    width: 6px;
                    height: 10px;

                    &:nth-child(1) {
                        background: $blue;
                    }
                    &:nth-child(2) {
                        background: $white;
                    }
                    &:nth-child(3) {
                        background: $red;
                    }
                }
            }
        }

    }

    .header-container{
        position: relative;
        @media(max-width:991px){
            height: 80px;
        }

        .container{
            position: relative;
        }        
    
        #header_logo{
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;
            position: relative;
            @media(max-width:991px){
                padding-top: 14px;
                padding-bottom: 10px;
                padding-left: 60px;
                padding-right: 100px;
                width: 100%;
            }

            .toggle-nav{
                display: none;
            }

            a{
                display: inline-block;
                transform: translateY(50%);
                -webkit-transform: translateY(50%);
                
                @media(max-width:991px) {
                    margin-top: -8px;
                }

                #logo{
                    width: 320px;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);

                    @media(max-width:991px){
                        width: 100%;
                        max-width: 170px;
                    }

                    .st0{
                        fill-rule:evenodd;
                        clip-rule:evenodd;
                        fill:$golden;
                    }
                    .st1{
                        fill : none;
                        stroke : $black;
                        stroke-width : 0.4;
                        stroke-miterlimit : 10;
                    }
                    .st2{
                        fill:$golden;
                        @media(max-width:991px){
                            //display: none;
                        } 
                    }          
                }
            }

            @media(max-width:991px){
                .toggle-nav{
                    display: block;
                    cursor: pointer;
                    width: 30px;
                    height: 26px;
                    position: absolute;
                    top: 16px;
                    left: 15px;
                    z-index: 1;

                    i{
                        font-size: 12px;
                        font-style:normal;
                        margin-top: -5px;
                        display: block;
                    }
        
                    span{
                        display: block;
                        width: 30px;
                        height: 2px;
                        background: $black;
                        @include transition(all,ease,.3s);
        
                        &:not(:last-child){
                            margin-bottom: 5px;
                        }
                    }

                    &.open{
                        top: 22px;
                        span:nth-child(1){
                            position: absolute;
                            top: 0;
                            transform-origin: bottom;
                            transform: rotate(45deg) translate(5px,5px);
                        }
                        span:nth-child(2){
                            display: none;
                        }
                        span:nth-child(3){
                            position: absolute;
                            top: 1px;
                            transform-origin: top;
                            transform: rotate(-45deg) translate(-4px,5px);
                        }
                        i{
                            display: none;
                        }
                    }

                }
            }
        }
    }
}