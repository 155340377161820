#adtm_menu{
    clear: both;
    position: relative;

    a{
        color: $black;
        @include transition(all,linear,0.4s);

        &:hover{
            text-decoration: none;
            color:lighten($black,30%) ;
        }
    }

    #menu{
        padding: 0;
        margin: 0;
        list-style-type: none;

        @media(min-width:992px){
            text-align: center;
        }

        @media(min-width:992px){
            .li-niveau1{
                display: inline-block;

                &.advtm_menu_12:after{
                    content: "";
                    display: inline-block;
                    background: $black;
                    width: 1px;
                    height: 20px;
                    margin-left: 5px;
                    margin-right: 5px;
                    transform: translateY(5px);
                    -webkit-transform: translateY(5px);           
                }

                a{
                    &.a-niveau1{
                        position: relative;
                        padding: 10px 15px;
                        display: inline-block;
                        
                        .advtm_menu_span{
                            font-family: $font;
                            font-size: 15px;
                            text-transform: uppercase;
                            color: $black;
                            letter-spacing: 1.3px;
                            @include transition(all,linear,0.4s);
                        }

                        &:after{
                            content: "";
                            display: block;
                            height: 4px;
                            background: $golden;
                            position: absolute;
                            width: 0;
                            left: 50%;
                            bottom: -4px;
                            @include transition(width,ease,0.4s);
                            transform: translateX(-50%);
                            -webkit-transform: translateX(-50%);
                        }
                        &:hover,
                        &:focus,
                        &.advtm_menu_actif{
                            text-decoration: none;

                            .advtm_menu_span{
                                color: $golden;
                            }
                            
                            &:after{
                                width: 100%;
                            }
                        }
                    }
                }
        
                .adtm_sub{
                    background: $grey;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 102;
                    margin-top: 4px;
                    padding-top: 60px;
                    padding-bottom: 60px;
                    width: 100%;
                    text-align: left;
                    display: none;

                    .container:after{
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        clear: both;
                    }

                    .column_wrap_title{
                        display: block;
                        text-transform: uppercase;
                        font-family: $font-bold;
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                    
                    ul{
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        margin-bottom: 20px;
                    }
                }

                &:hover{
                    .adtm_sub{
                        display: block;
                    }
                }
        
            }
        }
    }


    @media(max-width:991px){
        background: $grey;
        position: absolute;
        border-top: $golden solid 4px;
        top: 100%;
        left: -100%;
        z-index: 30;
        width: 100vw;
        height: 100vh;
        padding: 30px;

        -webkit-transition-property:left;
        transition-property:left;
        -webkit-transition-duration:0.5s;
        transition-duration:0.5s;
        -webkit-transition-delay:0s;
        transition-delay:0s;
        -webkit-transition-timing-function:cubic-bezier(1,0,.25,1);
        transition-timing-function:cubic-bezier(1,0,.25,1);
        
        &.open{
            left: 0;
        }

        #adtm_menu_inner{
            overflow-y: auto;
            height: 100%;
            -webkit-overflow-scrolling: touch;

            .container,
            [class^="col-"],
            [class*=" col-"]{
                padding: 0;
            }

            ul{
                padding: 0;
                margin: 0;
                list-style-type: none;
            }

            .li-niveau1 {
                position: relative;

                //pictos menu N1
                > a::before {
                    font-family: 'FontAwesome';
                    display: inline-block;
                    margin-right: 8px;
                    font-size: 24px;
                }
                &.advtm_menu_1 > a::before {
                    font-family: 'icomoon' !important;
                    content: "\e902";
                    transform: translateY(1px);
                }
                &.advtm_menu_2 > a::before {
                    content: "";
                }
                &.advtm_menu_3 > a::before {
                    content: "";
                    transform: translateY(1px);
                }
                &.advtm_menu_4 > a::before {
                    content: "";
                }
                

                .columnWrapTable {
                    .adtm_column::after {
                        content: "";
                        display: block;
                        width: 250px;
                        height: 1px;
                        background: $golden;
                        margin-top: 15px;
                        margin-bottom: 15px;
                    }
                    a {
                        font-size: 18px;
                        line-height: 1.2;
                    }

                    .adtm_elements {
                        li {
                            margin-top: 15px;
                        }
                        a {
                            font-size: 18px;
                        }
                    }
                }

                &.advtm_search{
                    padding-bottom: 20px;
                    label{
                        display: none;
                    }
                    .searchboxATM{
                        position: relative;

                        .form-control{
                            width: calc(100% - 60px);
                        }

                        .btn{
                            position: absolute;
                            top: 0;
                            right: 0;
                            z-index: 1;
                        }
                    }
                }

                &:last-child{
                    margin-bottom: 60px;
                }

                &.advtm_menu_6{
                    &:before{
                        content: "";
                        display: inline-block;
                        background: lighten($black,65%);
                        width: 100%;
                        height: 1px;
                        margin-bottom: 15px;
                    }
                    &:after{
                        margin-top:25px;
                    }    
                }

                a{
                    width: 90%;
                    display: inline-block;
                    &.a-niveau1{
                        display: inline-block;
                        .advtm_menu_span{
                            font-family: $font-semibold;
                            font-size: 24px;
                            line-height: 1.1;
                            text-transform: uppercase;
                            color: $black;
                            display: inline-block;
                            margin-bottom: 20px;
                        }
                    }
                }

                &.sub:after{
                    font-family: 'FontAwesome';
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                .adtm_sub{
                    display: none;
                }

                &.sub.open{
                    &:after{
                        content: "";
                    }

                    .adtm_sub{
                        display: block;
                    }                       
                }

                .adtm_sub{
                    margin-bottom: 15px;

                    [class^="col-"]:not(:last-child),
                    [class*=" col-"]:not(:last-child){
                        margin-bottom: 15px;
                    }
        
                    .column_wrap_title{
                        display: block;
                        text-transform: uppercase;
                        font-family: $font-bold;
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                }
                
    
            }
        }   
    }
}