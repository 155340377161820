#views_block{
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

    #thumbs_list{
        position: relative;
        &:before{
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
            transform: translate(-50%);
            content: "";
            display: block;
            width: calc(100% - 20px);
            height: 1px;
            background: lighten($black,65%);
        }

        .owl-dots{
            position: absolute;
            width: 100%;
            bottom: -25px;
            left: 0;
            z-index: 2;
        }

        ul{
            display: inline-block;
            list-style-type: none;
            padding: 0;
            margin: 0;
            text-align: center;
            width: 100%;

            &.margin li{
                margin-left: 10px;
                margin-right: 10px;
            }

            li{
                display: inline-block;

                a{
                    display: block;
                    border-radius: 100%;
                    border: lighten($black,70%) solid 5px;
                    overflow: hidden;
                    @include transition(border,ease,.3s);

                    &:hover{
                        border-color: darken($grey,10%); 
                    }

                    &.zoomThumbActive{
                        border-color: $golden;
                    }
                }
            }
        }
    }
}