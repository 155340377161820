body#contact{
    address{
        font-size: 16px;
        line-height: 1.6;

        .title{
            font-size: 20px;

            i{
                color: $golden;
            }
        }

        a{
            color: $black;
        }
    }

    form{
        label{
            font-size: 16px;
        }


        .row_fileUpload{

            &, *{
                cursor: pointer;
            }

            .label_fileUpload{
                font-size: 16px;
                line-height: 35px;
            }

            .btn{
                padding-left: 50px;
                padding-right: 50px;
                
            }

            #fileUpload{
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
            }
        }
    }
}