@font-face {
    font-family: 'myriad-pro-semi-bold';
    src: url('../fonts/myriad/MyriadPro-Semibold.eot');
    src: url('../fonts/myriad/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/myriad/MyriadPro-Semibold.woff2') format('woff2'),
        url('../fonts/myriad/MyriadPro-Semibold.woff') format('woff'),
        url('../fonts/myriad/MyriadPro-Semibold.ttf') format('truetype'),
        url('../fonts/myriad/MyriadPro-Semibold.svg#MyriadPro-Semibold') format('svg');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'myriad-pro';
    src: url('../fonts/myriad/MyriadPro-Regular.eot');
    src: url('../fonts/myriad/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/myriad/MyriadPro-Regular.woff2') format('woff2'),
        url('../fonts/myriad/MyriadPro-Regular.woff') format('woff'),
        url('../fonts/myriad/MyriadPro-Regular.ttf') format('truetype'),
        url('../fonts/myriad/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'myriad-pro-bold';
    src: url('../fonts/myriad/MyriadPro-Bold.eot');
    src: url('../fonts/myriad/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/myriad/MyriadPro-Bold.woff2') format('woff2'),
        url('../fonts/myriad/MyriadPro-Bold.woff') format('woff'),
        url('../fonts/myriad/MyriadPro-Bold.ttf') format('truetype'),
        url('../fonts/myriad/MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'bdmregular';
    src: url('../fonts/bdm/bdm-webfont.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}