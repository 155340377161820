@import "pages/items/thumbs";

body#product{
    .shipping-free{
        font-size: 16px;
        color: $golden;
    }

    .feature-quality {
        font-weight: 700;
        font-size: 18px;
    }
    .feature-default {
        color: #707070;
    }
    .title-table-features {
        font-size: 30px;
        font-weight: 700;
    }

    .add-to-cart{
        background: $grey;
        padding: 40px;

        @media(max-width:991px){
            margin-top: 30px;
            padding: 20px;

            h1{
                font-size: 24px;
            }
        }

        fieldset {
            background : none;
            border : none;
            padding: 0;

            input[type="checkbox"] {
                display: inline-block !important;
            }

            .attribute_label {
                font-weight: bold;
            }

            select {
                width: 100%;
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                &.radio-list {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;

                    label {
                        display: block;
                        background: rgba($white, 0.3);
                        padding: 5px;
                        border: #777 solid 1px;
                        cursor: pointer;

                        &::before {
                            display: none;
                        }
                    }
                }

                input:checked + label {
                    font-weight: bold;
                    border: $golden solid 1px;
                }
            }
        }

        h1{
            width: calc(100% - 50px);
        }

        .content_prices{
            margin-bottom: 30px;
        }

        #quantity_wanted_p{
            label{
                display: none;
            }
            #quantity_wanted{
                -moz-appearance:textfield;
                padding: 10px 16px;
                font-size: 18px;
                line-height: 1.33333;
                border-radius: 25px;
                height: auto;
                width: 74px;
                float: left;
                margin-right: 15px; 
                text-align: center;
                @media(max-width:991px){
                    margin-bottom: 15px;
                    width: 100%;
                }

                &::-webkit-inner-spin-button, 
                &::-webkit-outer-spin-button { 
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
            .quantity-btn{
                display: inline-block;

                @media(min-width:992px){
                    width: 30px;
                    float: left;
                    margin-right: 15px;
                }
                
                .btn{
                    padding: 4px 5px;
                    line-height: 1;

                    &.button-plus{
                        @media(min-width:992px){
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
        
        .exclusive{
            @media(max-width:991px){
                width: 100%;
            }
            padding-left: 30px;
            padding-right: 30px;
        }

        .box-security {
            img {
                display: inline-block;
                height: 28px;
                margin: 0 15px 5px 0;

                &:last-child {
                    margin-left: 0;
                }
            }
        }


        @media(max-width:991px){
            .cart-mobile{
                .exclusive{
                    position: fixed;
                    top: 50%;
                    right: 30px;
                    z-index: 90;
                    width: auto;
                    padding:0;
                    font-size: 24px;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 60px;
                    text-align: center;
                    
                    span{
                        display: none;
                    }
                }
            }
        }

        .socialsharing_product{
            margin:30px -40px 0 -40px;
            padding:30px 40px 0 40px;
            border-top: $white solid 1px;

            @media(max-width:991px){
                margin:20px -20px 0 -20px;
                padding:20px 20px 0 20px;
            }

            .btn{
                font-size: 24px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                padding: 0;
                margin:0 5px;
            }
        }
    }

    .pb-left-column:not(.last-img) {
        .customization-text {
            display: none;
        }
    }

    #image-block{
        position: relative;

        #view_full_size{
            width: 100%;
            display: block;

            @media(max-width: 460px) {
                border: lighten($black,65%) solid 1px;
            }

            a{
                display: block;
                
                &:hover{
                    border: lighten($black,65%) solid 1px;
                }
            }
            .zoomPad{
                z-index: 2;
                text-align: center;
                width: 100%;
                .zoomWrapper{
                    border-color: lighten($black,65%) !important;
                }
                img.img-responsive{
                    display: inline-block;
                }
            }
        }
        
        .customization-text{
            font-family: $bdm;
            font-size: 36px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            z-index: 100;
            width: 90%;
            text-align: center;
        }

        &.total-1{
            .customization-text.textField0{
                top: 47%;

                &[data-length="12"]{
                    &.category-90 {
                        top: 34%;
                    }
                }
            }
        }
        &.total-2{
            .customization-text.textField0{
                top: 31%;

                &[data-length="12"]{
                    top: 22%;
                }
            }
            .customization-text.textField1{
                top: 61%;

                &[data-length="12"]{
                    top: 39%;
                }
            }
        }
        &.total-3{
            .customization-text.textField0{
                top: 18%;
            }
            .customization-text.textField1{
                top: 48%;
            }
            .customization-text.textField2{
                top: 79%;
            }
        }
        &.total-4{
            .customization-text.textField0{
                top: 12%;
            }
            .customization-text.textField1{
                top: 35%;
            }
            .customization-text.textField2{
                top: 58%;
            }
            .customization-text.textField3{
                top: 81%;
            }
        }


        &:hover{
            .customization-text{
                display: none;
            }
        }
    }
    

    #customizationForm{
        ul{
            padding: 0;
            margin: 0;
            list-style-type: none;
        }
        textarea{
            resize: none;
        }
    }

    .picture-no-real{
        font-size: 13px;
        margin-top: 30px;
        margin-bottom: 30px;
        .title{
            font-style: italic;
        }
        p{
            color: lighten($black,40%);
        }
    }

    .description-product{
        margin-top: 60px;
        background: $grey;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 30px;
        font-size: 15px;

        h2{
            margin-top: 0;
        }
        #spiral-category{
            width: 95%;
            height: auto;
            margin: -50px auto;

            .spiral-category{
                fill:lighten($black,60%);
            }
        }
    }

    .table-data-sheet{
        font-size: 16px;
        th{
            background: none;
        }
    } 
    .img_manu {
        max-width: 120px;
        margin-top: 15px;
    }

    #color_to_pick_list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        &::before {
            display: none;
        }

        a {
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            border: $grey solid 3px;
            transition: all ease .3s;
            overflow: hidden;

            img {
                width: 50px;
                height: 50px;
                object-fit: cover;
                object-position: center center;
            }
        }
        li {
            width: 50px;
        }
        .selected a {
            border-color: $golden;
        }
        span {
            display: block;
            font-size: 11px;
            line-height: 1.1;
            margin-top: 5px;
            text-align: center;
        }
    }
}