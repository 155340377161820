#HOOK_PAYMENT{
    margin-left: -15px;
    margin-right: -15px;

    #stripe-card-payment {
		float: left;
		width: calc(100% - 30px);
		margin-left: 15px;
        margin-right: 15px;
		background: $white !important;
        border: $input-border solid 2px !important;
        border-radius: $input-border-radius !important;
        color: $black;

        @media(min-width:992px){
            width: calc(33.333333% - 30px);
        }

        .form-row::before {
            content: 'Paiement sécurisé par CB ci-dessous';
            text-align: center;
            font-size: 18px;
            margin-bottom: 10px;
            display: block;
            font-weight: normal;
        }

        .card-payment-informations {
            display: none !important;
        }
	}

    .payment_module{
        padding-left: 15px;
        padding-right: 15px;
        float: left;
        width: 100%;
        margin-bottom: 30px;

        &.payplugPayment a::after {
            display: none;
        }

        .js-payment-ps_checkout-card button {
            background: $golden !important;
            color: $white !important;
            opacity: 1 !important;

            span {
                color: inherit !important;
                font-style: normal !important;
            }
        }

        a{
            display: block;
            padding: 20px;
            min-height: 165px;
            border: $input-border solid 2px;
            border-radius: $input-border-radius;
            color: $black;
            text-align: center;
            font-size: 18px;
            line-height: 1.1;
            font-weight: normal !important;
            background: $white;
            @include transition(all,ease,.3s);

            &:hover{
                background: $grey;
                text-decoration: none;
            }
            

            .paypal-marks {
                &::before {
                    content: '';
                    width: 100%;
                    height: 70px;
                    background: url(../img/payment/logo-paypal.png) no-repeat center center;
                    background-size: contain;
                    display: block;
                    margin: 0 auto 10px auto;
                }
                .paypal-mark {
                    display: none !important;
                }
            }
            &.ps_checkout-card {
                .ps_checkout-mark {
                    &::before {
                        content: '';
                        width: 100%;
                        height: 70px;
                        background: url(../img/payment/logo-cb.png) no-repeat center center;
                        background-size: contain;
                        display: block;
                        margin: 0 auto 10px auto;
                    }

                    img {
                        display: none !important;
                    }
                }
            }

            .ps_checkout-mark,
            img,
            span{
                font-size: 14px;
                display: block !important;
                margin: 0 auto;
                font-style: italic;
                color: lighten($black,40%);
            }
            img{
                margin-bottom: 10px;
            }

            &::after {
                display: none !important;
            }
        }

        @media(min-width:992px){
            width: 33.333333%;
        }

        &.payplugPayment a img {
            max-height: 75px !important;
        }
    }
}