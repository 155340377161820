.ac_results{
    border: none !important;

    @include container-fixed;

    @media (min-width: $screen-sm-min) {
        width: calc(#{$container-sm} - 30px) !important;
    }
    @media (min-width: $screen-md-min) {
        width: calc(#{$container-md} - 30px) !important;
    }
    @media (min-width: $screen-lg-min) {
        width: calc(#{$container-lg} - 30px) !important;
    }

    li{
        padding: 10px !important;

        &.ac_over{
            background: $golden;
        }
    }
}