#history{
    table{
        td{
            vertical-align: middle !important;

            .label{
                font-weight: normal;
                font-size: 12px;
            }
        }
    }
}