@import "common/theme_variables";
@import "mixins/transitions";
@import "mixins/shadows";


body#order-opc{

    .delivery_options{

        .table {
            .delivery_option_radio{
                text-align: center;
                vertical-align: middle !important;
				
                label,
				label:before{
                    margin: 0 !important;
                }
            }

			td {
				vertical-align: middle !important;
			}

            @media(max-width:768px){
                td{
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }

    .product-name a{
        color: $black;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: normal;
        line-height: 1.3;
        display: block;
        margin-bottom: 10px;
        @include transition(all,ease,.3s);
        &:hover{
            color: lighten($black,40%);
            text-decoration: none;
        }

        strong{
            font-weight: normal;
        }
    }
}

#cart_summary {
	background: $white;
    ul{
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
	thead {
		th {
			padding: 7px 8px 9px 8px;
		}
	}
	tbody {
		td {
            padding: 7px 8px 9px 8px;

			&.cart_product {
				padding: 7px;
				width: 98px;
			}
			&.cart_unit {
				.price {
					span {
						display: inline-block;
						&.price-percent-reduction {
							margin: 5px auto;
							display: inline-block;
						}
						&.old-price {
							text-decoration: line-through;
						}
					}
				}
			}
			&.cart_description {
				small {
					display: block;
					padding: 5px 0 0 0;
				}
			}
			&.cart_avail{
				width: 1%;
				span {
					white-space: nowrap;
				}
            }
            &.cart_quantity{
                input{
                    margin-bottom: 10px;
                    text-align: center;
                }
                a{
                    margin-bottom: 5px;
                }
            }
			&.cart_delete{
                width: 5%;
                a{
                    font-size: 20px;
                    color: $black;
                }
			}
		}
	}
	tfoot {
		td {
            vertical-align: middle !important;
			&.text-right {
				color: $black;
			}
			&.price {
				text-align: right;
			}
			&.total_price_container {
				span {
					font: 600 18px / 22px $font;
					color: #555454;
					text-transform: uppercase;
				}
			}
			&#total_price_container {
				font: 28px / 30px $font;
				background: $grey;
			}
		}
	}
}

#cart_summary .stock-management-on {
	tbody {
		td {
			&.cart_description {
				width: 480px;
			}
		}
	}
}

.cart_discount_price{
  text-align: right;
}

.cart_discount_delete{
  text-align: center;
}

@media (max-width: $screen-xs-max) {
	#order-detail-content {
		#cart_summary {
			table, thead, tbody, th, td, tr {
				display: block;
			}
			thead tr {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}
			tr {
				border-bottom: 1px solid #cccccc;
				/*padding-bottom: 7px;*/
				overflow: hidden;
				position: relative;
			}
			td {
				border: none;
				position: relative;
				float: left;
				white-space: normal;
				padding: 7px;
				&.cart_product {
					text-align: left;
				}
				&.cart_avail {
					display: none;
				}
				&.cart_unit {
					width: 33%;
					text-align: right;
					min-height: 70px;
					clear: both;
					.price {
						text-align: right;
					}
				}
				&.cart_quantity {
					div {
						display: block;
					}
					&>.form-control {
						display: inline;
					}
					&>.cart_quantity_button a {
						margin-right: 0px;
                        float: none;
                        padding:2px 7px;
					}
					width: 33%;
					min-height: 70px;
					border-left: 1px solid #cccccc;
					border-right: 1px solid #cccccc;
				}
				&.cart_total {
					width: 33%;
					min-height: 70px;
					text-align: right;
				}
				&.cart_delete {
					position: absolute;
					right: 20px;
					top: 7px;
					&:before {
						content: '' !important;
					}
				}
				div {
					display: inline;
				}
			}
			td:before {
				content: attr(data-title);
				font-weight: bold;
				display: block;
			}
			tfoot {
				td {
					float: none;
                    width: 100%;
					&:before {
						display: inline;
					}
				}
				tr {
					.text-right, .price {
						display: block;
						float: left;
						width: 50%;
					}
				}
			}
		}
	}
}

@media (max-width: $screen-sm) {
	#order-detail-content {
		#cart_summary {
			tbody {
				td {
					.price {
						text-align: center;
					}
					&.cart_description {
						width: 100%;
					}
				}
			}
		}
	}
}

/************ cart voucher ***********/
.cart_voucher {
    vertical-align: top !important;
    background: $grey;
    padding: 15px !important;
    
    fieldset{
		background: none !important;
        margin-bottom: 10px;
        padding: 0;
        border: none;
    }
    #discount_name {
        float: left;
        width: 219px;
        margin-right: 5px;
        margin-bottom: 5px;
    }
    h4 {
        font: 600 18px / 22px $font;
        color: $black;
        text-transform: uppercase;
        margin-top: 0;
    }
    
    .title-offers {
        color: $black;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
    }
    #display_cart_vouchers {
        span {
            font-weight: bold;
            cursor: pointer;
            color: $black;
            &:hover {
                color: lighten($black,50%);
			}
			
			&.voucher_name {
				color: $golden;
				transition: all ease .3s;

				&:hover {
					color: darken($golden, 15%);
				}
			}
        }
    }
}