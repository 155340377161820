@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon/icomoon.eot?74ehry');
    src:  url('../fonts/iicomoon/comoon.eot?74ehry#iefix') format('embedded-opentype'),
      url('../fonts/icomoon/icomoon.ttf?74ehry') format('truetype'),
      url('../fonts/icomoon/icomoon.woff?74ehry') format('woff'),
      url('../fonts/icomoon/icomoon.svg?74ehry#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-cart:before {
    content: "\e900";
  }
  .icon-profile:before {
    content: "\e901";
  }
  .icon-spiral:before {
    content: "\e902";
  }
  