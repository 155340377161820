//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  margin-bottom: $line-height-computed;
  list-style: none;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 0;

  @media(max-width:991px){
    margin-bottom: 10px;
  }

  > li {
    display: inline-block;
    letter-spacing: 1.2px;

    + li:before {
      $nbsp: ">";
      content: "#{$nbsp}";
      padding: 0 5px;
      color: $breadcrumb-color;
    }
  }

  a{
      color:lighten($black,40%);
      @include transition(all,ease,.3s);
      &:hover{
          color: lighten($black,40%);
          text-decoration: none;
      }
  }
  strong{
    font-weight: normal;
  }

  /*a,strong{
    display: inline-block;
    vertical-align: top;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span{
      display: inline-block;
  vertical-align: top;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    }
  }*/

}
