body#module-loyalty-default {
    .total-point-loyalty {
        font-size: 3rem;
        font-family: $font-bold;
        text-align: center;
        margin-bottom: 30px;
        color: $golden;

        &::before, &::after {
            content: "";
            display: block;
            width: 150px;
            height: 1px;
            background: $golden;
            margin: 15px auto;
        }
    }
    .point-loyalty {
        color: $golden;
        font-family: $font-bold;
    }
    .btn {
        white-space: initial;
    }
}

#HOOK_SHOPPING_CART {
    p#loyalty {
        width: 100%;
        margin-bottom: 60px;
        background: $golden;
        padding: 15px;
        border-radius: 5px;
        color: $white;
        vertical-align: middle;
        display: inline-block;
        font-size: 18px;

        i {
            vertical-align: middle;
            margin-right: 10px;
        }
    }
}