@import "common/theme_variables";
@import "mixins/transitions";
@import "mixins/shadows";

#left_column{
	margin-bottom: 30px;

	@media(max-width:991px) {
		.filters-container {
			.title-filters {
				border-radius: 15px;
				background: $golden;
				font-size: 18px;
				color: $white;
				padding:0 15px;
				height: 48px;
				line-height: 48px;
				vertical-align: middle;
				width: 100%;
				//float: left;
				//margin-top: -48px;
				cursor: pointer;
			}
			.filters{
				display: none;
			}
			&.open {
				.filters{
					display: block;
				}
				.title-filters {
					border-top-left-radius: 15px;
					border-top-right-radius: 15px;
					border-bottom-left-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}
	
	ul{
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	#layered_block_left{
		margin-bottom: 30px;
	}

	#categories_block_left,
	.content-layered,
	.layered_price{
		padding:20px;
		background:$grey;
		border-bottom: $white solid 1px;

		.remove-filters{
			background: $golden;
			color: $white;
			padding: 5px 15px;
			border-radius: 15px;
			cursor: pointer;
			@include transition(all,ease,.3s);

			&:hover{
				background: darken($golden,15%);
			}
		}

		.layered_subtitle,
		.title_block{
			display: block;
			font-size: 18px;
			margin-bottom: 15px;
			position: relative;
			letter-spacing: 1.3px;

			span{
				display: inline-block;
				background:$grey;
				position: relative;
				z-index: 1;
				padding-right: 15px;
			}

			&:after{
				content: "";
				display: inline-block;
				width: 100%;
				height: 1px;
				background: lighten($black,60%);
				position: absolute;
				top: 50%;
				left: 0;
				z-index: 0;
				transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
			}
		}

		&:not(.layered_price){
			.block_content{
				max-height: 250px;
				overflow-x: hidden;
				overflow-y: auto;
			}
		}

		ul{
			&:after{
				content: "";
				clear: both;
				display: block;
				width: 100%;
				height: 1px;
			}
			li:not(:last-child){
				margin-bottom: 8px;
			}
			a{
				font-size: 15px;
				color: $black;
				@include transition(all,ease,.3s);
				&:hover{
					color: lighten($black,40%);
					text-decoration: none;
				}
			}
		}

		.layered_slider_container{
			padding-top: 10px;

			*{
				-ms-touch-action:pan-x;
				touch-action:pan-x;
			}

			.ui-widget-content{
				border: none !important;
				background: lighten($black,55%);
				height: 6px;
	
				.ui-widget-header{
					background: $golden;
				}
	
				.ui-slider-handle{
					top: -6px;
					background-image: none;
					background: $white;
					border-radius: 100%;
					border: none;
					@include shadows(1px,1px,0,0,rgba($black,0.8));
				}
			}
		}

		ul:not(#ul_layered_id_feature_2):not(#ul_layered_id_feature_3){
			vertical-align: top;
			line-height: 1;

			input[type=checkbox]:checked + label:before{
				transform: translateY(3px);
				-webkit-transform: translateY(3px);
			}


			label{
				width: 100%;

				&:before{
					float: left;
				}
				a{
					display: block;
					float: left;
					max-width: 85%;
					line-height: 1.2;
					/*padding-top: 3px;*/
				}
			}
			input{
				margin-top: 0;
				vertical-align: top;
			}
		}

		#ul_layered_id_feature_2,
		#ul_layered_id_feature_3{
			li{
				float: left;
				width: 40px;
				box-sizing: content-box;
				padding-left: 5px;
				padding-right: 5px;

				input[type=checkbox]{
					display: none;

					& + label:before{
						display: none;
					}
				}

				input[type=checkbox]:checked + label{
					a{
						span{
							border-color:$golden;
						}
					}
				}

				label{
					display: block;
					cursor: pointer;

					a{
						font-size: 11px;
						line-height: 1;
						text-align: center;
						display: block;

						span{
							display: block;
							width: 40px;
							height: 40px;
							border-radius: 100%;
							margin-bottom: 5px;
							border: transparent solid 3px;
							background-position: center center;
							background-repeat: no-repeat;
							background-size: 40px 40px;
						}
					}
				}
			}
		}

		#ul_layered_id_feature_3{
			li{
				width: 60px !important;

				label{
					a{
						span{
							width: 60px !important;
							height: 60px !important;
							background-size: 40px 40px;
						}
					}
				}
			}	
		}
	}
}

body#category,
body#new-products,
body#prices-drop,
body#search{
    .heading-counter{
		font-size: 18px;
		@media(max-width:991px){
			display: block;
			margin-bottom: 15px;
		}
		@media(min-width:992px){
			float: left;
		}
		margin-bottom: 30px;

        span{
            color: $golden;
        }
    }

    .content_scene_cat{
        position: relative;
		margin-bottom: 30px;
		background: $white;
		padding: 30px;
		border: lighten($black,60%) solid 2px;
		
		@media(max-width:767px){
			margin-bottom: 15px;
			padding:30px 15px;
			img{
				margin-bottom: 30px;
			}
		}

        .col-md-7{
            @media(max-width:991px){
                padding-left: 30px;
                padding-right: 30px;
            }
            @media(max-width:767px){
                padding: 0 15px;
			}
		}
		#category_description_short{
			padding: 0;
			font-size: 15px;
			@media(max-width:767px){
				padding: 0;
			}
	
			h1{
				vertical-align: top;
				margin-top: 0;
				margin-bottom: 20px;
				font-size: 28px;
				letter-spacing: 1.3px;
				@media(max-width:991px){
					margin-bottom: 10px;
				}
				span{
					line-height: 1;
					vertical-align: top;
				}
			}
			a:not(.btn){
				color: $black;
				@include transition(all,ease,.3s);
				&:hover{
					color: lighten($black,40%);
					text-decoration: none;
				}
			}

			.btn{
				margin-top: 10px;
			}
		}
    }

    #productsSortForm {
		margin-bottom: 30px;
		
		@media(max-width:991px){
			display: none;
			border-top: $golden solid 2px;
			border-bottom: $golden solid 2px;
			padding: 5px;
			width: 50%;
			float: right;
			margin-bottom: 0 !important;
		}

        select{
			width: 250px;
			@media(max-width:991px){
				width: 100%;
			}
        }
        
        label{
            font-size: 15px;
			margin-right: 8px;
			@media(max-width:991px){
				display: none;
			}
        }
	}

    #product_list.row{
        margin-left: -5px;
        margin-right: -5px;
    }

    #category_description_full{
        background: $grey;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 30px;
        font-size: 15px;

        h2{
            margin-top: 0;
        }
        #spiral-category{
            width: 95%;
            height: auto;
            margin: -50px auto;

            .spiral-category{
                fill:lighten($black,60%);
            }
        }
    }
}