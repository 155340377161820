*{
	outline: none;
}
*:focus{
    text-decoration: none;
}

body {
	font-size: 16px;
}

main{
	padding-bottom: 60px;
	min-height: 550px;
}

.unvisible{
	display: none;
}

.price{
	font-size: 24px;
	font-family: $font-semibold;
}
.old-price,
#old_price{
	font-size: 20px;
	text-decoration: line-through;
	color: lighten($black,30%);
}
.price-percent-reduction,
#reduction_percent{
	position: absolute;
	top: -10px;
	left: -10px;
	z-index: 15;
	background: $golden;
	color: $white;
	font-size: 12px;
	width: 55px;
	height: 55px;
	padding-top: 15px;
	text-align: center;
	line-height: 1;
	border-radius: 100%;

	span{
		display: block;
		font-size: 16px;
	}
}
#reduction_percent{
	top: 10px;
	left: 10px;
}

/******* format margin ****************/
.no-margin{
	margin:0 !important;
}
.no-margin-top{
	margin-top:0 !important;
}
.no-margin-bottom{
	margin-bottom:0 !important;
}
.margin-top{
	margin-top:15px;
}
.margin-top-2x{
	margin-top:30px;
}
.margin-top-4x{
	margin-top:60px;
}
.margin-bottom{
	margin-bottom:15px;
}
.margin-bottom-2x{
	margin-bottom:30px;
}
.margin-bottom-4x{
	margin-bottom:60px;
}

/******* format padding ****************/
.no-padding{
	padding:0;
}
.no-padding-top{
	padding-top:0;
}
.no-padding-bottom{
	padding-bottom:0;
}
.padding-top{
	padding-top:15px;
}
.padding-top-2x{
	padding-top:30px;
}
.padding-top-4x{
	padding-top:60px;
}
.padding-bottom{
	padding-bottom:15px;
}
.padding-bottom-2x{
	padding-bottom:30px;
}
.padding-bottom-4x{
	padding-bottom:60px;
}

/************* couleurs txt **********************/
.text-white{
	color:$white;
}
.text-black{
	color:$black;
}
.text-grey{
	color:lighten($black,65%);
}
.text-golden{
	color:$golden;	
}

/************* format txt ************************/
.cap{
	text-transform:uppercase;
}
.bold{
	font-weight:bold;
}
.no-bold{
	font-weight:normal !important;
}

/*************** square-back **********************/
.square-back{
	position: relative;

	&:before,
	&:after{
		content: "";
		display: block;
		background: $golden;
		position: absolute;
		z-index: 1;
		bottom: -10px;
	}

	&:before{
		width: 10px;
		height: 270px;
	}
	&:after{
		width: 270px;
		height: 10px;
	}

	&.square-left{
		&:before{
			left: -10px;
		}
		&:after{
			left: 0px;
		}
	}
	&.square-right{
		&:before{
			right: -10px;
		}
		&:after{
			right: 0px;
		}
	}

	&.square-sm{
		&:before{
			width: 10px;
			height: 150px;
		}
		&:after{
			width: 150px;
			height: 10px;
		}
	}
}

.icon-ag {
	background : url(../img/title-ag.svg) no-repeat;
	background-size: 18px 28px;
	display: inline-block;
	width: 18px;
	height: 28px;
}

.label-warning,
.label-success {
	background: none !important;
}