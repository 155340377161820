.myaccount-link-list{
    margin-top: 30px;
    padding: 0;
    list-style-type: none;
    float: left;
    width: 100%;
    border: lighten($black, 60%) solid 2px;
    background: $white;

    li{
        padding: 0;

        a{
            display: block;
            padding: 20px;
            text-align: center;
            font-size: 18px;
            color: $black;
            @include transition(all,ease,.3s);
            border: lighten($black, 60%) solid 1px;

            &:hover,
            &:focus{
                text-decoration: none;
                background: $grey;
            }

            i{
                display: block;
                color: $golden;
                font-size: 30px;
                margin-bottom: 10px;
            }
        }
    }
}