.pagenotfound{
    h1{
        font-size: 60px;
        line-height: 1;
        text-align: center;

        i{
            color: $golden;
        }

        span{
            display: block;
            font-size: 100px;
        }
    }
    #search_query{
        margin-right: 10px;
        width: calc(100% - 135px);
    }
}